import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Homepage
        {
          name: 'Homepage',
          path: '',
          component: () => import('@/views/dashboard/Homepage'),
        },
        // Pages
        {
          name: 'Research',
          path: '/Research',
          component: () => import('@/views/dashboard/pages/Research'),
        },
        {
          name: 'CV',
          path: '/CV',
          component: () => import('@/views/dashboard/pages/CV'),
        },
        {
          name: 'TPEC',
          path: '/TPEC',
          component: () => import('@/views/dashboard/pages/TPEC'),
        },
        {
          name: 'Power Market',
          path: '/PowerMarket',
          component: () => import('@/views/dashboard/pages/PowerMarket'),
        },
        // Tables
        {
          name: 'Photography',
          path: '/Photography',
          component: () => import('@/views/dashboard/pages/Photography'),
        },
      ],
    },
  ],
})
