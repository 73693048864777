import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

// import ECharts modules manually to reduce bundle size
import {
  CanvasRenderer,
} from 'echarts/renderers'
import {
  BarChart,
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
])

Vue.use(Vuetify)
Vue.component('v-chart', ECharts)

const theme = {
  primary: '#454545',
  secondary: '#454545',
  accent: '#454545',
  info: '#454545',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
